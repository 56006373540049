<div *ngIf="loadingTest.status$ | async">
    <app-spinner></app-spinner>
</div>
<div class="d-flex w-100">
    <div class="w-100">
        <app-header class="app-header" *ngIf="userauthentication && showHeader"></app-header>
        <router-outlet></router-outlet>
        <app-footer class="app-footer" *ngIf="userauthentication && showHeader"></app-footer>
    </div>
</div>
<p-toast key="tst" position="top-right"></p-toast>
<p-messages></p-messages>
