import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@app/authentication/services/authentication.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService, // private tokenRefreshService: TokenRefreshService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err) => {
                if ([401, 403].indexOf(err.status) !== -1) {
                    this.authenticationService.logout();
                } else if (err.status === 500 && request.url.toLowerCase().indexOf('refresh-token') > 0) {
                    this.authenticationService.logout();
                }
                const error = err;
                return throwError(error);
            }),
        );
    }
}
