import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SoaFormService } from '../services/soa-form.service';
import { MessageService } from 'primeng/api';
import moment from 'moment';
import { AgentModel, LeadModel, SoaFormLookupModel } from '@app/soa/services/model/soa-form.model';
import SignaturePad from 'signature_pad';

@Component({
    selector: 'app-soa-form',
    templateUrl: './soa-form.component.html',
    styleUrls: ['./soa-form.component.scss'],
})
export class SoaFormComponent implements OnInit {
    soaId = '';
    currentDate: Date;
    loading: boolean;
    agentSign: string;
    appointmentDate: string;
    agentDetails = {} as AgentModel;
    leadDetails = {} as LeadModel;
    agentSalesId: string;
    methodOfContact: string;
    hideSoaForm = true;
    thankyouForm: boolean;
    successContent: string;
    form: UntypedFormGroup;
    signaturePad;
    Data: Array<any> = [
        { name: 'Medicare Advantage Plans (Part C) and Cost Plans', value: 'Medicare Advantage Plans (Part C) and Cost Plans' },
        { name: 'Stand-alone Medicare Prescription Drug Plan (Part D)', value: 'Stand-alone Medicare Prescription Drug Plan (Part D)' },
        { name: 'Medicare Supplement (Medigap) Plan', value: 'Medicare Supplement (Medigap) Plan' },
        { name: 'Dental-Vision-Hearing Products', value: 'Dental-Vision-Hearing Products' },
        { name: 'Hospital Indemnity Products', value: 'Hospital Indemnity Products' },
    ];

    constructor(private formBuilder: UntypedFormBuilder, private route: ActivatedRoute, private SoaFormSvc: SoaFormService, private msgService: MessageService) {
        this.form = this.formBuilder.group({
            name: ['', Validators.required],
            relatioName: [''],
            selectedArray: this.formBuilder.array([], [Validators.required]),
        });
    }

    ngOnInit(): void {
        this.soaId = this.route.snapshot.paramMap.get('id');
        this.currentDate = new Date();
        this.getSoaContractList();
        setTimeout(() => {
            this.signaturePad = new SignaturePad(document.querySelector('canvas'));
        }, 1000);
    }

    getSoaContractList() {
        this.loading = true;
        this.SoaFormSvc.getSoaContractById(this.soaId).subscribe({
            next: (res) => {
                this.loading = false;
                if (res['signName'] && res['signName'] !== '') {
                    this.hideSoaForm = false;
                    this.thankyouForm = true;
                    this.successContent = 'you have filled Scope of Appointment Confirmation Form';
                } else {
                    const getAgentId = res['agent']['id'];
                    this.agentDetails = res['agent'];
                    this.leadDetails = res['benefitiary'];
                    this.getAgentDetails(getAgentId);
                    this.agentSalesId = res['agentSalesId'];
                    this.appointmentDate = res['appointmentDate'];
                    this.methodOfContact = res['methodOfContact'];
                }
            },
            error: () => {
                this.msgService.add({
                    key: 'tst',
                    severity: 'erroe',
                    summary: 'Error',
                    detail: 'Something went wrong, please try again',
                });
            },
        });
    }

    getAgentDetails(agentId: string) {
        this.SoaFormSvc.getAgentDetailsById(agentId).subscribe({
            next: (res) => {
                this.agentSign = JSON.parse(res['value']).ESignature;
            },
            error: () => {
                this.msgService.add({
                    key: 'tst',
                    severity: 'erroe',
                    summary: 'Error',
                    detail: 'Unable to fetch agent details, please try again',
                });
            },
        });
    }

    onCheckboxChange(e) {
        const selectedArray: UntypedFormArray = this.form.get('selectedArray') as UntypedFormArray;

        if (e.target.checked) {
            selectedArray.push(new UntypedFormControl(e.target.value));
        } else {
            let i = 0;
            selectedArray.controls.forEach((item: UntypedFormControl) => {
                if (item.value === e.target.value) {
                    selectedArray.removeAt(i);
                    return;
                }
                i++;
            });
        }
    }

    async submitForm() {
        if (this.form.controls.selectedArray.errors?.required) {
            alert('You need to check at least one of the options to discuss');
            return false;
        }
        
        if (this.signaturePad.isEmpty()) {
            alert('Full and Actual Signature is Required');
            return false;
        }
        if (this.form.value.name === '') {
            alert('name is Required');
            return false;
        }
        const soaForm: SoaFormLookupModel = {
            id: this.soaId,
            potentialMedicarePlans: this.form.value.selectedArray,
            signName: this.form.value.name,
            relationshipToBeneficiary: this.form.value.relatioName,
            base64: this.signaturePad.toDataURL().replace('data:image/png;base64,', ''),
            checkboxSelected1: this.form.value.selectedArray.includes(this.Data[0].value),
            checkboxSelected2: this.form.value.selectedArray.includes(this.Data[1].value),
            checkboxSelected3: this.form.value.selectedArray.includes(this.Data[2].value),
            checkboxSelected4: this.form.value.selectedArray.includes(this.Data[3].value),
            checkboxSelected5: this.form.value.selectedArray.includes(this.Data[4].value),
            todayDate: moment(new Date()).format('MMM D, YYYY'),
            name: this.form.value.name,
            representativeName: `${this.agentDetails.firstName} ${this.agentDetails.lastName}`,
            representativePhone: this.agentDetails.cellPhone,
            representativeId: this.agentSalesId,
            beneficiaryName: this.leadDetails.firstName,
            beneficiaryPhone: `${this.leadDetails.cellPhone || this.leadDetails.workPhone || this.leadDetails.homePhone}`,
            beneficiaryDate: moment(this.appointmentDate).format('MM/DD/YYYY'),
            userSignature: this.agentSign,
        };

        this.loading = true;
        this.SoaFormSvc.signUpContract(soaForm).subscribe({
            next: () => {
                this.loading = false;
                this.hideSoaForm = false;
                this.thankyouForm = true;
                this.successContent = 'Thank you for filling Scope of Appointment Confirmation Form';
                this.msgService.add({
                    key: 'tst',
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Scope of agreement created',
                });
            },
            error: () => {
                this.hideSoaForm = true;
                this.loading = false;
                this.msgService.add({
                    key: 'tst',
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Something went wrong, please try again',
                });
            },
        });
    }
}
