import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@shared/authentication/auth.guard';
import { ComingSoonComponent } from './shared/coming-soon/coming-soon.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { SearchComponent } from './shared/search/search/search.component';
import { UnauthorizedComponent } from './shared/unauthorized/unauthorized.component';
import { SoaFormComponent } from './soa/soa-form/soa-form.component';

const routes: Routes = [
    // {
    //     path: 'admin',
    //     loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    // },
    {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full',
    },

    {
        path: 'login',
        loadChildren: () => {
            return import('./authentication/authentication.module').then((m) => {
                return m.AuthenticationModule;
            });
        },
    },
    {
        path: 'dashboard',
        loadChildren: () => {
            return import('./dashboard/dashboard.module').then((m) => {
                return m.DashboardModule;
            });
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'users',
        loadChildren: () => {
            return import('./users/users.module').then((m) => {
                return m.UsersModule;
            });
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'leads',
        loadChildren: () => {
            return import('./lead/lead.module').then((m) => {
                return m.LeadModule;
            });
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'search',
        component: SearchComponent,
        data: { title: 'search' },
        canActivate: [AuthGuard],
    },
    {
        path: 'soa/:id',
        component: SoaFormComponent,
    },
    {
        path: 'clients',
        loadChildren: () => {
            return import('./client/client.module').then((m) => {
                return m.ClientModule;
            });
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'users',
        loadChildren: () => {
            return import('./users/users.module').then((m) => {
                return m.UsersModule;
            });
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'communication',
        loadChildren: () => {
            return import('./communication/communication.module').then((m) => {
                return m.CommunicationModule;
            });
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'calendar',
        loadChildren: () => {
            return import('./calendar/calendar.module').then((m) => {
                return m.CalendarModule;
            });
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'lead-status',
        loadChildren: () => {
            return import('./lead-status/lead-status.module').then((m) => {
                return m.LeadStatusModule;
            });
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'user-settings',
        loadChildren: () => {
            return import('./user-settings/user-settings.module').then((m) => {
                return m.UserSettingsModule;
            });
        },
        // canActivate: [AuthGuard],
    },
    {
        path: 'reports',
        loadChildren: () => {
            return import('./reports/reports.module').then((m) => {
                return m.ReportsModule;
            });
        },
        // canActivate: [AuthGuard],
    },

    {
        path: 'coming-soon',
        component: ComingSoonComponent,
    },
    {
        path: 'unauthorized',
        component: UnauthorizedComponent,
    },
    // otherwise redirect to home
    { path: '**', component: PageNotFoundComponent },
    // { path: '', pathMatch: 'full', redirectTo: '/dashboard' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
