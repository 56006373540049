/* eslint-disable linebreak-style */
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { environment } from '@environments/environment';

const separator = '|';

@Injectable({ providedIn: 'root' })
export class TitleService {
    constructor(private router: Router, private activatedRoute: ActivatedRoute, private titleService: Title) {}
    static ucFirst(text: string): string {
        if (!text) {
            return text;
        }
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    init(): void {
        this.router.events
            .pipe(
                filter((event) => {
                    return event instanceof NavigationEnd;
                }),
                map(() => {
                    let route = this.activatedRoute;
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                filter((route) => {
                    return route.outlet === 'primary';
                }),
                map((route) => {
                    return route.snapshot;
                }),
                map((snapshot) => {
                    if (snapshot.data.title) {
                        if (snapshot.paramMap.get('id') !== null) {
                            return `${snapshot.data.title as string}${separator}${snapshot.paramMap.get('id')}`;
                        }
                        return snapshot.data.title as string;
                    } else {
                        return this.router.url.split('/').reduce((acc, frag) => {
                            if (acc && frag) {
                                acc = `${acc}${separator}`;
                            }
                            return this.router.url.split('/').reduce((acc, frag) => {
                                if (acc && frag) {
                                    acc = `${acc}${separator}`;
                                }
                                return `${acc}${TitleService.ucFirst(frag)}`;
                            });
                        });
                    }
                }),
            )
            .subscribe((pathString) => {
                return this.titleService.setTitle(`${this.environmentIndicator()}${environment.pageTitle}-${pathString}`);
            });
    }
    private environmentIndicator(): string {
        if (environment.environment === 'prod') {
            return '';
        }
        return `[${environment.environment}] `;
    }
}
