import { Injectable } from '@angular/core';
import { HttpUtility } from '@utility/httpUtility';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { SoaFormLookupModel } from './model/soa-form.model';

@Injectable({ providedIn: 'root' })
export class SoaFormService {
    constructor(private httpUtility: HttpUtility) { }

    signUpContract(payload): Observable<SoaFormLookupModel[]> {
        return this.httpUtility.put<SoaFormLookupModel[]>(`${environment.apiUrl}/soa-contract/sign-contract`, payload);
    }

    getSoaContractById(id: string) {
        return this.httpUtility.get(`${environment.apiUrl}/soa-contract/${id}`);
    }

    getAgentDetailsById(id: string) {
        return this.httpUtility.get(`${environment.apiUrl}/user-personas/getbyuser/${id}`);
    }
}
