/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, Injector, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ServiceInjector } from 'core/service/service-injector.service';
import { AuthenticationModule } from './authentication/authentication.module';
import { SharedModule } from './shared/shared.module';

import { ExcelService } from '@shared/excel.service';
import { PrimenNgModule } from '@shared/prime-ng.module';
import { ErrorInterceptor } from '@utility/interceptor/error.interceptor';
import { JwtInterceptor } from '@utility/interceptor/jwt.interceptor';
import { LoggerService } from 'core/service/logger.service';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { SoaFormComponent } from './soa/soa-form/soa-form.component';

@NgModule({ declarations: [AppComponent, SoaFormComponent],
    bootstrap: [AppComponent],
    schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA], imports: [BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        SharedModule,
        AuthenticationModule,
        ToastModule,
        LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG }),
        ProgressSpinnerModule,
        PrimenNgModule,
        NgxMaskDirective,
        NgxMaskPipe], providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        MessageService,
        ConfirmationService,
        LoggerService,
        ExcelService,
        provideNgxMask(),
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
    constructor(private readonly injector: Injector) {
        ServiceInjector.Injector = this.injector;
    }
}
