<ng-container>
    <header class="py-4">
        <div class="soa-container d-flex flex-row align-items-center">
            <div class="px-3">
                <img height="40px" src="./assets/media/msis/main-logo.png" />
            </div>
            <h1 class="w-100 text-center">Scope of Appointment</h1>
        </div>
    </header>
    <div id="soaForm" *ngIf="hideSoaForm">
        <div class="content-form py-4">
            <div class="soa-container">
                <form [formGroup]="form" (ngSubmit)="submitForm()" novalidate>
                    <h2 class="text-center py-3">Scope of Appointment Confirmation Form</h2>
                    <div class="contract-list">
                        <p class="text-center pb-3">
                            Before meeting with a Medicare beneficiary (or their authorized representative), Medicare requires that Licensed Sales Representatives use this form to ensure your
                            appointment focuses only on the type of plan and products you are interested in. A separate form should be used for each Medicare beneficiary. Please check what you want to
                            discuss with the Licensed Sales Representative.
                        </p>
                        <hr />
                        <p class="d-flex flex-row align-items-center mb-0" style="flex-wrap: wrap">
                            <label *ngFor="let data of Data; let i = index" class="pr-3 d-flex flex-row align-items-center">
                                <input type="checkbox" name="name{{ i }}" [value]="data.value" (change)="onCheckboxChange($event)" />
                                {{ data.name }}
                            </label>
                        </p>
                        <p>
                            By signing this form, you agree to meet with a Licensed Sales Representative to discuss the products checked above. The Licensed Sales Representative is either employed or
                            contracted by a Medicare plan and may be paid based on your enrollment in a plan. They do not work directly for the federal government.
                        </p>
                        <p class="pb-3">
                            Signing this form does not affect your current or future enrollment in a Medicare plan, enroll you in a Medicare plan or obligate you to enroll in a Medicare plan. All
                            information provided on this form is confidential.
                        </p>
                        <h3>Beneficiary or Authorized Representative Signature and Signature Date</h3>
                        <hr />
                        <div class="d-flex flex-row justify-content-between">
                            <div class="d-flex flex-column justify-content-start align-items-start w-50">
                                <p class="font-medium">Signature of applicant/member/authorized representative</p>
                                <canvas id="signature-pad" class="signature-pad" width=500 height=150></canvas>
                                <button type="button" pButton pRipple label="Clear" icon="pi pi-trash" class="p-button-secondary my-4" (click)="signaturePad.clear()"></button>
                            </div>
                            <div class="w-50 text-center">
                                <h6 class="font-medium mb-0 light-gray">Today's Date</h6>
                                <h4 class="font-normal pt-2 m-0">{{ currentDate | date }}</h4>
                            </div>
                        </div>
                        <p class="font-medium">If you are the authorized representative, please sign above and enter your name and relationship below:</p>
                        <div class="d-flex flex-row align-items-center mt-2">
                            <label class="w-50 text-right pr-3 mb-0">Name</label>
                            <input type="text" value="" formControlName="name" placeholder="First and Last name" class="form-control form-control-lg form-control-solid" />
                        </div>
                        <div class="d-flex flex-row align-items-center mt-4">
                            <label class="w-50 text-right pr-3 mb-0">Relationship to Beneficiary </label>
                            <input type="text" value="" formControlName="relatioName" placeholder="Relationship to Beneficiary" class="form-control form-control-lg form-control-solid" />
                        </div>
                        <p class="font-medium py-4 mb-0">To be completed by Licensed Sales Representative (please print clearly and legibly)</p>
                        <div class="row">
                            <div class="col-4">
                                <p>Licensed Sales Representative Name</p>
                                <p class="mb-0 light-gray">{{ agentDetails.firstName }} {{ agentDetails.lastName }}</p>
                            </div>
                            <div class="col-4">
                                <p>Licensed Sales Representative Phone</p>
                                <p class="mb-0 light-gray">{{ agentDetails.cellPhone | mask: '(000) 000-0000' }}</p>
                            </div>
                            <div class="col-4">
                                <p>Licensed Sales Representative ID</p>
                                <p class="mb-0 light-gray">{{ agentSalesId }}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                <p>Beneficiary Name</p>
                                <p class="mb-0 light-gray">{{ leadDetails.firstName }}</p>
                            </div>
                            <div class="col-4">
                                <p>Beneficiary Phone</p>
                                <p class="mb-0 light-gray">{{ leadDetails.cellPhone || leadDetails.workPhone || leadDetails.homePhone | mask: '(000) 000-0000' }}</p>
                            </div>
                            <div class="col-4">
                                <p>Date Appointment will be Completed</p>
                                <p class="mb-0 light-gray">{{ appointmentDate | date: 'MM/dd/YYYY' }}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <p>Beneficiary Address</p>
                                <p class="mb-0 light-gray">{{ leadDetails.address }}, {{ leadDetails.city }} {{ leadDetails.state }} {{ leadDetails.county }} {{ leadDetails.zipCode }}</p>
                            </div>
                        </div>
                        <div class="flex flex-row align-items-center py-2">
                            <p class="w-25 mb-0">Initial Method of Contact</p>
                            <p class="mb-0 w-75 light-gray">{{ methodOfContact }}</p>
                        </div>
                        <div class="flex flex-row align-items-center py-2">
                            <p class="w-25 mb-0">Call IN</p>
                            <p class="mb-0 w-75 light-gray">MAPD, MAD, PDP, MED SUPS</p>
                        </div>
                        <div class="flex flex-column py-2">
                            <p class="font-medium">Licensed Sales Representative Signature</p>
                            <img style="max-width: 210px" [src]="agentSign" />
                        </div>
                        <div class="py-5 text-center">
                            <button pButton type="submit" label="Submit" class="p-button-primary"></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="content-form py-4">
            <div class="soa-container">
                <h3>Medicare Advantage Plans (Part C) and Cost Plans</h3>
                <p class="py-2">
                    <span class="font-medium">Medicare Health Maintenance Organization (HMO)</span> - A Medicare Advantage Plan that provides all Original Medicare Part A and Part B health coverage
                    and sometimes covers Part D prescription drug coverage. In most HMOs, you can only get your care from doctors or hospitals in the plan’s network (except in emergencies).
                </p>
                <hr />
                <p class="py-2">
                    <span class="font-medium">Medicare HMO Point-of-Service (HMO-POS)</span> - A Medicare Advantage Plan that provides all Original Medicare Part A and Part B health coverage and
                    sometimes covers Part D prescription drug coverage. HMO-POS plans may allow you to get some services out of network for a higher copayment or coinsurance.
                </p>
                <hr />
                <p class="py-2">
                    <span class="font-medium">Medicare Preferred Provider Organization (PPO) Plan</span> - A Medicare Advantage Plan that provides all Original Medicare Part A and Part B health
                    coverage and sometimes covers Part D prescription drug coverage. PPOs have network doctors, providers and hospitals but you can also use out-of-network providers, usually at a
                    higher cost.
                </p>
                <hr />
                <p class="py-2">
                    <span class="font-medium">Medicare Private Fee-For-Service (PFFS) Plan</span> - A Medicare Advantage Plan in which you may go to any Medicare-approved doctor, hospital and provider
                    that accepts the plan’s payment, terms and conditions and agrees to treat you — not all providers will. If you join a PFFS Plan that has a network, you can see any of the network
                    providers who have agreed to always treat plan members. You will usually pay more to see out-of-network providers.
                </p>
                <hr />
                <p class="py-2">
                    <span class="font-medium">Medicare Special Needs Plan (SNP)</span> - A Medicare Advantage Plan that has a benefit package designed for people with special health care needs.
                    Examples of the specific groups served include people who have both Medicare and Medicaid, people who reside in nursing homes, and people who have certain chronic medical
                    conditions. providers who have agreed to always treat plan members. You will usually pay more to see out-of-network providers.
                </p>
                <hr />
                <p class="py-2">
                    <span class="font-medium">Medicare Medical Savings Account (MSA) Plan</span> - MSA Plans combine a high deductible health plan with a bank account. The plan deposits money from
                    Medicare into the account. You can use it to pay your medical expenses until your deductible is met.
                </p>
                <hr />
                <p class="py-2">
                    <span class="font-medium">Medicare Cost Plan</span> - In a Medicare Cost Plan, you can go to providers both in and out of network. If you get services outside of the plan’s
                    network, your Medicare-covered services will be paid for under Original Medicare but you will be responsible for Medicare coinsurance and deductibles.
                </p>
                <hr />
                <h3>Stand-alone Medicare Prescription Drug Plans (Part D)</h3>
                <p class="py-2">
                    <span class="font-medium">Medicare Prescription Drug Plan (PDP)</span> - A stand-alone drug plan that adds prescription drug coverage to Original Medicare, some Medicare Cost
                    Plans, some Medicare Private-Fee-For-Service Plans, and Medicare Medical Savings Account Plans.
                </p>
                <hr />
                <h3>Other Related Products</h3>
                <p class="py-2">
                    <span class="font-medium">Medicare Supplement (Medigap) Products</span> - Insurance plans that help pay some of the out-of-pocket costs not paid by Original Medicare (Parts A and
                    B) such as deductibles and co-insurance amounts for Medicare approved services.
                </p>
                <hr />
                <p class="py-2">
                    <span class="font-medium">Dental/Vision/Hearing Products</span> - Plans offering additional benefits for consumers who are looking to cover needs for dental, vision, or hearing.
                    These plans are not affiliated or connected to Medicare.
                </p>
                <hr />
                <p class="py-2">
                    <span class="font-medium">Hospital Indemnity Products</span> - Plans offering additional benefits; payable to consumers based upon their medical utilization; sometimes used to
                    defray copays/coinsurance. These plans are not affiliated or connected to Medicare.
                </p>
            </div>
        </div>
        <p class="text-center">SCOPE OF APPOINTMENT DOCUMENTATION IS SUBJECT TO CMS RECORD RETENTION REQUIREMENTS</p>
    </div>
    <div class="" *ngIf="thankyouForm">
        <div class="soa-container my-5 py-5">
            <div class="contract-list">
                <h3 class="text-center">{{ successContent }}</h3>
            </div>
        </div>
    </div>
</ng-container>

<div *ngIf="loading" class="spinner-view-soa">
    <app-inline-loader></app-inline-loader>
</div>

<p-toast key="tst" position="top-right"></p-toast>
<p-messages></p-messages>
